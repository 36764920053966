import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import { Footer } from '../components/footer'
import styled from 'styled-components'
import programm from './pdf/part2/20-1-000455 Программа итогового семинара полная.pdf'
import programm2 from './pdf/part2/ВОЛОНТЕРСТВО 28.10.pdf'

const LayoutCatalog = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 30px 1rem;

  .about_wrapper {
    width: 100%;
  }

  h1 {
    font-family: 'GT Walsheim Pro Bold';
  }

  h2 {
    font-family: 'GT Walsheim Pro Bold';
    font-size: 1.5em;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  .about_images {
    margin-bottom: 10vw;
  }
`

const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  border-radius: 8px;
  height: 200px;
  margin: 30px 0;

  img {
    width: 50%;
  }

  &:hover {
    background-color: #fafafa;
  }

  .first {
    display: flex;
    justify-content: center;
  }
  .second {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    color: #000;
    margin-right: 15px;
  }
  .second-head {
    font-family: 'GT Walsheim Pro Bold';
    padding-bottom: 15px;
  }
`

const Icon = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > span {
    padding-left: 10px;
  }

  .icon {
    display: inline-block;
    min-width: 40px;
    width: 40px;
    height: 50px;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    margin: 2px 5px;
  }
  .icon::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-radius: 0 2px;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .icon--pdf {
    background-color: #db4437;
  }
  .icon--pdf::after {
    background: linear-gradient(45deg, #e78178 50%, #fff 50%);
  }
  .icon--pdf i::before {
    content: '☵';
  }

  .icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    font-size: 10px;
    color: #fff;
    font-weight: 500;
  }
  .icon i::before,
  .icon i::after {
    display: block;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  .icon i::before {
    text-align: center;
    font-size: 12px;
    opacity: 0;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  .icon i::after {
    content: attr(title);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  &:hover .icon {
    border-radius: 2px 4px 2px 2px;
  }
  &:hover .icon::after {
    width: 12px;
    height: 12px;
  }
  &:hover .icon i::before {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  &:hover .icon i::after {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`

class VolunteerManual extends React.Component {
  render() {
    // const news = this?.props?.data?.allContentfulNews?.edges

    return (
      <Layout location="/volunteer-news">
        <LayoutCatalog>
          <Helmet title="Методические материалы проекта" />
          <div className="about_wrapper">
            <h1>Методические материалы проекта</h1>

            <h2>Вебинары обучающего курса</h2>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Rp_jKeN_goQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/AWOwrY4oX1Y"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/wxHqY-3grpY"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/0obzVq83azs"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Qh9LN1Jvk6E"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>

            <h2>Программа практического семинара</h2>
            <Icon href={programm} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>Программа итогового семинара.pdf</span>
            </Icon>

            <h2>Аналитический бюллетень по результатам изучения и анализа опыта волонтёрской деятельности в сфере сохранения культурного наследия</h2>
            <Icon href={programm2} target="_blank" rel="noopener noreferrer">
              <div className="icon icon--pdf">
                <i title="pdf"></i>
              </div>
              <span>Аналитический бюллетень.pdf</span>
            </Icon>
            
          </div>
        </LayoutCatalog>
        <Footer
          text="При финансовой поддержке Российского фонда культуры в рамках проекта № 21-3-000455 «Волонтёрская деятельность в сфере сохранения и популяризации культурного наследия: лучшие практики и инициативы»"
        />
      </Layout>
    )
  }
}

export default VolunteerManual
